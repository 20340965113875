<template>
  <div class="content">
    <PageHeader :title="$t('sms.history.header')" />
    <div class="page-content container-fluid">
      <card tabs actions>
        <template #header>
          <ul class="nav nav-tabs primary-tabs p-l-30 m-0">
            <li class="nav-item" role="presentation">
              <router-link :to="{ name: 'CustomerEdit' }" class="nav-link">{{
                $t('app.statistics')
              }}</router-link>
            </li>
            <li class="nav-item" role="presentation">
              <router-link
                :to="{ name: 'CustomerShortCodes' }"
                class="nav-link"
                >{{ $t('app.history') }}</router-link
              >
            </li>
          </ul>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
// import moment from 'moment';

export default {
  name: 'SmsHistory',
  components: {
    PageHeader,
    Card,
  },
};
</script>
